import React, { Fragment, useState, useEffect } from 'react'
import {
  Layout,
  Menu,
  Divider,
  Row,
  Col,
  Dropdown,
} from 'antd'
import { 
  Link,
  withRouter,
} from 'react-router-dom'
import './LayoutGuest.module.scss'
// import './LayoutGuest.module.scss'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'
import logo from '../../assets/LogoOnlineTesting.svg'
import LogoIpst from '../../assets/home/logo_ipst.svg'
import LogoGlo from '../../assets/home/logo_glo1.svg'
import LogoLearning from '../../assets/home/logoLearning.svg'
import Facebook from '../IconSVG/facebook_icon.svg'
import { connect } from 'react-redux'
import window from 'global'
// import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './LayoutGuest.module.scss'

const { Header, Content, Footer } = Layout
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env

const LayoutGuest = (props) => {
  const { serverStatus } = props
  const [ isModalOffline, setIsModalOffline ]  = useState(false)

  useEffect(() => {
    // console.log(props.location.pathname)
    const { pathname } = props.location
    if (pathname != '/login' && pathname != '/forget-password' && pathname != '/register' && pathname != '/contact') {
      const script = document.getElementById('recaptchaScript')
      if (script) {
        // console.log('remove script')
        script.remove()
      }

      const badge = document.getElementsByClassName('grecaptcha-badge')
      if (badge.length > 0) {
        // console.log('remove badge')
        badge[0].remove()
      }
    }
    else {
      const recaptchaScript = document.getElementById('recaptchaScript')
      if (!recaptchaScript) {
        const script = document.createElement('script')
        script.id = 'recaptchaScript'
        script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`
        script.async = true
        document.body.appendChild(script)
      }
    }
  }, [props.location.pathname])

  let layoutBG = null 
  if(props.location.pathname == '/'){
    layoutBG = styles.bgInHome
  }else if(props.location.pathname == '/student'){
    layoutBG = styles.bgInStudent
  }else if(props.location.pathname == '/teacher'){
    layoutBG = styles.bgInTeacher
  }else if(props.location.pathname == '/about'){
    layoutBG = styles.bgInAboutUs
  }else if(props.location.pathname == '/contact'){
    layoutBG = styles.bgInContactUs
  }else if(['/login', '/forget-password'].includes(props.location.pathname)){
    layoutBG = styles.bgInLogin
  }else if(props.location.pathname == '/register'){
    layoutBG = styles.bgInRegister
  }else if(props.location.pathname == '/faqguest'){
    layoutBG = styles.bgInFaq
  }

  const menuItems = (
    <Menu>
      <Menu.Item key='student'>
        <Link to='/student'>นักเรียน</Link>
      </Menu.Item>
      <Menu.Item key='teacher'>
        <Link to='/teacher'>ครู/อาจารย์</Link>
      </Menu.Item>
    </Menu>
  )
  const goToRegister = () => {  
    const { serverStatus } = props  
    if(serverStatus && serverStatus.isOffline) {
      setIsModalOffline(true)
    }else{
      props.history.push('/register')
    }
  }
  const goToContact = () => { 
    const { serverStatus } = props   
    if(serverStatus && serverStatus.isOffline) {
      setIsModalOffline(true)
    }else{
      props.history.push('/contact')
    }
  }
  const containerMenu = (
    <div className='container'>
      <div className='left'>
        {/* <Link to='/'> */}
        { props.location.pathname === '/' ?
          <a href = '/'></a> 
          :
          <div style = {{paddingLeft : ''}}>
            <a href = '/' ><img src={logo} alt='logo' style = {{marginLeft : '',width : 80, marginTop : 25}}/></a> 
          </div>
        }
        {/* </Link> */}
        <Menu
          mode='horizontal'
          className={`${styles.layoutMenu} ${styles.layoutMenuHideMobile}`}
          selectedKeys={[ props.location.pathname ]}
          id = 'lay_123'
        >
          <Menu.Item key='/student'>
            <Link to='/student'>นักเรียน</Link>
          </Menu.Item>
          <Menu.Item key='/teacher'>
            <Link to='/teacher'>ครู/อาจารย์</Link>
          </Menu.Item>
        </Menu>
      </div>

      <div className='right'>        
        <Menu
          mode='horizontal'
          selectedKeys={[ props.location.pathname ]}
          className={styles.layoutMenu}
        >
          <Menu.Item key='/login' className = {styles.loginTab}>
            <Link to='/login'>เข้าสู่ระบบ</Link>
          </Menu.Item>

          <Menu.Item key='/register' className = {styles.loginTab}>
            <a onClick={goToRegister}>สมัครสมาชิก</a>
          </Menu.Item>

          <Menu.Item key ='/menuItem'>
            <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
              <a className='ant-dropdown-link'>
                เมนู
              </a>
            </Dropdown>
          </Menu.Item> 
        </Menu>
      </div>
      <div className='rightMobile'>        
        <Menu
          mode='horizontal'
          selectedKeys={[ props.location.pathname ]}
          className={styles.layoutMenu}
        >
          <Menu.Item key='/login' className = {styles.loginTab} style = {{width : 51, left : '-1%'}}>
            <Link to='/login'>เข้าสู่ระบบ</Link>
          </Menu.Item>

          <Menu.Item key='/register' className = {styles.loginTab} style = {{width : 66, left : '-2%'}}>
            {/* <Link to='/register'>สมัครสมาชิก</Link> */}
            <a onClick={goToRegister}>สมัครสมาชิก</a>
          </Menu.Item>

          <Menu.Item key ='/menuItem' style = {{left : '2%'}}>
            <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
              <a className='ant-dropdown-link'>
                เมนู
              </a>
            </Dropdown>
          </Menu.Item> 
        </Menu>
      </div>

    </div>
  ) 

  return (
    <div className={layoutBG ? layoutBG : styles.notFound } >
      <div className={styles.bgtophome}></div>
      {
        props.location.pathname == '/' ? 
          <div className={styles.layoutTopHeader}>
            <Menu  className={styles.layoutHeader} mode='horizontal'>
              <Menu.Item>
                <a href='https://learningspace.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'><img src={LogoLearning} alt='logo' /></a>
              </Menu.Item>
              <Menu.Item>
                <a href='https://teacherpd.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'>
                  <div>ระบบอบรมครู<div>Teacher PD System</div></div>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href='https://onlinetesting.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'>
                  <div>ระบบการสอบออนไลน์<div>Online Testing System</div></div>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href='https://www.scimath.org/' rel='noopener noreferrer' target = '_blank'>
                  <div>ระบบคลังความรู้<div>SciMath</div></div>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href='https://myipst.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'>
                  <div>ระบบจัดการเรียนรู้แบบออนไลน์<div>My IPST</div></div>
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href='https://proj14.ipst.ac.th/' rel='noopener noreferrer' target = '_blank'>
                  <div>เว็บไซต์ Project 14<div>Project 14</div></div>
                </a>
              </Menu.Item>
              <Menu.Item style={{ display: 'flex' }}>
                <a href='http://www.ipst.ac.th' rel='noopener noreferrer' target = '_blank'><img src={LogoIpst} alt='logo' className={styles.onlineLogo} /></a>
                <a href='http://www.glo.or.th/' rel='noopener noreferrer' target = '_blank'><img src={LogoGlo} alt='logo' className={styles.onlineLogo} /></a>
              </Menu.Item>
            </Menu>
          </div>
          :
          null
      }
      <Layout id = 'forCheckPath' className={styles.layout} >
        <Header className={styles.header_guest}>
          {
            props.location.pathname === '/' ? (
              <Fragment>
                <div className='mobile'>
                  <div className='container'>
                    <div className='left'>
                      <Menu
                        mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        className={styles.layoutMenu}
                      >
                        <Menu.Item key='/login' >
                          <Link to='/login'>เข้าสู่ระบบ</Link>
                        </Menu.Item>

                        <Menu.Item key='/register'>
                          {/* <Link to='/register'>สมัครสมาชิก</Link> */}
                          <a onClick={goToRegister}>สมัครสมาชิก</a>
                        </Menu.Item>

                      </Menu>
                    </div>

                    <div className='rightMobile'>
                      <Dropdown overlay={menuItems} className='dropdown-menu' trigger={['click']}>
                        <a className='ant-dropdown-link'>
                          เมนู
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className='desktop'>
                  {containerMenu}
                </div>
              </Fragment>
            ) : containerMenu
          }
        </Header>

        <Content className={styles.layoutContent} >
          {props.children}
        </Content>
 
        <Footer className={styles.layoutFooter} >
          <Divider/>
          <Row>
            {
              window.screen && window.screen.width >=  991 ?
                <div>
                  <Col sm={9} xs={24} md = {12}>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'about'
                        style = {{marginLeft : -20}}
                      >
                        <Menu.Item key='/about'>
                          <a href='/about'>เกี่ยวกับเรา</a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'contact'
                      >
                        <Menu.Item key='/contact'>
                          <a onClick={goToContact}>ติดต่อเรา</a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                    <Col xs={{ span: 7, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'lay_3'
                      >
                        <Menu.Item key='/faqguest'>
                          <a href='/faqguest'>คำถามที่พบบ่อย</a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                  </Col>

                  <Col sm ={1} md = {2} xs = {0}>
                  </Col>

                  <Col sm ={9} md = {10} xs={24} justify = {'end'} style = {{display : 'flex'}}>
                    <Col span = {11} sm = {11} >
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'lay_4'
                      >
                        <Menu.Item key='/term'>
                          <a href='/term'>เงื่อนไขและข้อกำหนด</a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                    <Col span = {9} sm = {11} >
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'lay_5'
                      >
                        <Menu.Item key='data_pivacy'>
                          <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                    <Col sm = {1} xs = {24}>
                      <Menu mode='horizontal'
                        selectedKeys={[ props.location.pathname ]}
                        id = 'lay_5'
                      >
                        <Menu.Item key='/facebook'>
                          <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                        </Menu.Item>
                      </Menu>
                    </Col>
                  </Col>
                </div>
                :
                window.screen && window.screen.width >=  768 ?
                  <div>
                    <Col>
                      <Col xs={{ span: 2, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                        <Menu mode='horizontal'
                          selectedKeys={[ props.location.pathname ]}
                          id = 'about'
                          style = {{marginLeft : -20}}
                        >
                          <Menu.Item key='/about'>
                            <a href='/about'>เกี่ยวกับเรา</a>
                          </Menu.Item>
                        </Menu>
                      </Col>
                      <Col xs={{ span: 2, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                        <Menu mode='horizontal'
                          selectedKeys={[ props.location.pathname ]}
                          id = 'contact'
                        >
                          <Menu.Item key='/contact'>
                            <a onClick={goToContact}>ติดต่อเรา</a>
                          </Menu.Item>
                        </Menu>
                      </Col>

                      <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                        <Menu mode='horizontal'
                          selectedKeys={[ props.location.pathname ]}
                          id = 'lay_3'
                        >
                          <Menu.Item key='/faqguest'>
                            <a href='/faqguest'>คำถามที่พบบ่อย</a>
                          </Menu.Item>
                        </Menu>
                      </Col>
                  
                      <Col justify = {'end'} style = {{display : 'flex'}}>
                        <Col span = {11} sm = {11} >
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_4'
                          >
                            <Menu.Item key='/term'>
                              <a href='term'>เงื่อนไขและข้อกำหนด</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col span = {9} sm = {11} >
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_5'
                          >
                            <Menu.Item key='data_pivacy'>
                              <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                        <Col sm = {1} xs = {24}>
                          <Menu mode='horizontal'
                            selectedKeys={[ props.location.pathname ]}
                            id = 'lay_5'
                          >
                            <Menu.Item key='/facebook'>
                              <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                            </Menu.Item>
                          </Menu>
                        </Col>
                      </Col>
                    </Col>
                  </div>
                  :
                  window.screen && window.screen.width < 768 ?
                    <div>
                      <Col>
                        <div style = {{display : 'flex', justifyContent : 'center'}}>
                          <Col>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'about'
                            >
                              <Menu.Item key='/about'>
                                <a href='/about'>เกี่ยวกับเรา</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col >
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'contact'
                            >
                              <Menu.Item key='/contact'>
                                <a onClick={goToContact}>ติดต่อเรา</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'lay_3'
                            >
                              <Menu.Item key='/faqguest'>
                                <a href='/faqguest'>คำถามที่พบบ่อย</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                        </div>
                        <div style = {{display : 'flex', justifyContent : 'center'}}>
                          <Col>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'lay_4'
                            >
                              <Menu.Item key='/term'>
                                <a href='term'>เงื่อนไขและข้อกำหนด</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                          <Col >
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'lay_5'
                            >
                              <Menu.Item key='data_pivacy'>
                                <a href='/data_pivacy'>นโยบายความเป็นส่วนตัว</a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                        </div>
                        <div style = {{display : 'flex', justifyContent : 'center'}}>
                          <Col>
                            <Menu mode='horizontal'
                              selectedKeys={[ props.location.pathname ]}
                              id = 'lay_5'
                            >
                              <Menu.Item key='/facebook'>
                                <a href = 'https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'><img src={Facebook}/></a>
                              </Menu.Item>
                            </Menu>
                          </Col>
                        </div>
                      </Col>
                    </div>
                    :
                    null
            }
          </Row>
          {
            window.screen && window.screen.width < 768 ? 
              <div className = {'copyrightLabel'}>
                <p className='text-thin' style ={{color : 'white', marginBottom : 15}} >© 2562 สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)</p>
              </div>
              :
              <div className = {styles.copyrightLabel}>
                <p className='text-thin' style ={{color : 'white'}} >© 2562 สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี (สสวท.)</p>
              </div>
          }
        </Footer>
        
      </Layout>
      <ModalServerUnavailable serverStatus={serverStatus} isModalOffline={isModalOffline} setIsModalOffline={setIsModalOffline} isLayoutGuest={ props.match.url === '/' ? true : false}/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    serverStatus: state.master.serverStatus,
  }
}

export default withRouter(connect(mapStateToProps)(LayoutGuest))

// export default withRouter(withStyles(styles)(connect(mapStateToProps)(LayoutGuest)))
